<template>
  <dashboard-layout>
    <div class="w-full flex flex-col">
      <h1 class="text-green-400 font-bold text-4xl w-full text-center">
        Buscar <span class="text-green-500">Oportunidades</span>
      </h1>
      <form @submit.prevent="submit" class="w-10/12 lg:w-6/12 mx-auto">
        <div class="flex flex-col">
          <div
            class="flex mt-3 rounded-full items-center overflow-hidden shadow-md bg-white"
          >
            <input
              class="text-gray-500 placeholder-gray-500 place w-full py-3 px-5 outline-none font-normal"
              type="text"
              name="search"
              autocomplete="off"
              placeholder="Busque por: Tratorista, Vaqueiro, Operador de Máquinas..."
              v-model="search"
              @input="searchKeyword"
            />
            <button
              type="submit"
              class="text-gray-500 cursor-pointer py-3 px-3 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <i class="ri-search-line text-2xl"></i>
            </button>
          </div>
          <div
            v-if="keywords.length > 0"
            class="flex flex-col w-full bg-white rounded-lg mt-4 py-2 px-4 max-h-64 overflow-scroll"
          >
            <div
              class="cursor-pointer my-1 hover:bg-gray-100"
              v-for="keyword in keywords"
              :key="keyword"
              @click="selectKeyword(keyword)"
            >
              {{ keyword }}
            </div>
          </div>
        </div>
        <div class="w-6/12 mx-auto text-center mt-3">
          <button
            type="button"
            class="font-bold text-green-500 focus:outline-none"
            @click="showAdvancedSearch = !showAdvancedSearch"
          >
            Busca Avançada
          </button>
        </div>
        <div
          v-if="showAdvancedSearch"
          class="w-full bg-white rounded-lg py-3 px-5 mx-auto mt-3"
        >
          <div class="flex flex-col w-full mb-5">
            <label class="flex items-center mb-2" for="company">
              Empresa
              <field-tooltip
                text="Selecione ou digite o nome da empresa."
              ></field-tooltip>
            </label>
            <multiselect
              v-model="advancedSearch.companies.selected"
              id="company"
              label="name"
              track-by="name"
              placeholder="Pesquise a empresa aqui..."
              open-direction="bottom"
              :options="advancedSearch.companies.options"
              :searchable="true"
              selected-label="Selecionado"
              deselect-label="Remover"
              select-label="Selecionar"
              :loading="advancedSearch.companies.isLoading"
              :internal-search="false"
              :options-limit="100"
              :max-height="600"
              :show-no-results="false"
              @search-change="fetchCompanies"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
              <span slot="noResult">Oops! Nenhuma empresa encontrada.</span>
              <span slot="noOptions"
                >Comece a pesquisar para que as empresas apareçam aqui.</span
              >
            </multiselect>
          </div>

          <div class="sm:flex mb-5 w-full">
            <div class="flex w-full flex-col">
              <label class="flex items-center mb-2" for="cities">
                Municípios
                <field-tooltip
                  text="Informe o nome dos municípios dos quais deseja buscar as oportunidades."
                ></field-tooltip>
              </label>
              <multiselect
                id="cities"
                v-model="advancedSearch.cities.selected"
                tag-placeholder="Adicionar cidade"
                placeholder="Procure ou adicione uma cidade"
                label="name"
                track-by="name"
                :options="advancedSearch.cities.filteredOptions"
                :multiple="true"
                :taggable="true"
                select-label="Selecionar"
                deselect-label="Remover"
                selected-label="Selecionado"
                @tag="addCity"
                :internal-search="false"
                @search-change="citiesSearchChange"
              >
                <span slot="noOptions"
                  >Comece a pesquisar para que as cidades apareçam aqui.</span
                >
              </multiselect>
            </div>
          </div>

          <button
            type="submit"
            class="focus:outline-none rounded-md py-2 bg-green-600 text-white text-center w-full text-xl cursor-pointer"
          >
            Aplicar filtros
          </button>
        </div>
      </form>

      <div class="w-10/12 lg:w-6/12 mx-auto">
        <loader-animation v-if="isLoading" />
        <div class="flex flex-col" v-if="!isLoading">
          <div
            v-if="true"
            class="w-full bg-white rounded-lg py-3 px-5 mx-auto mt-3"
          >
            <div class="flex flex-col w-full mb-5">
              <h1
                v-if="vacancySchedules.data == ''"
                class="text-2xl font-bold mb-5"
              >
                Nenhuma oportunidade disponivel...
              </h1>
              <h1 v-else class="text-2xl font-bold mb-5">
                Oportunidades Disponíveis
              </h1>
            </div>
            <div class="flex flex-col w-full mb-5">
              <div v-for="vacancy in vacancySchedules.data" :key="vacancy.id">
                <div class="border-2 rounded-lg border-green-1002 p-3 mb-8">
                  <div class="h-full flex items-start">
                    <div class="flex-col w-full">
                      <div class="flex-grow">
                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Titulo:
                            <span class="title-font font-medium text-gray-900">
                              {{ vacancy.office }}
                            </span>
                          </span> </a
                        ><br />

                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Empresa:
                            <span class="title-font font-medium text-gray-900">
                              {{ vacancy.company_name }}
                            </span>
                          </span> </a
                        ><br />

                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Quantidade de Oportunidades:
                            <span class="title-font font-medium text-gray-900">
                              {{ vacancy.vacancies_available }}
                            </span>
                          </span> </a
                        ><br />

                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Local:
                            <span
                              class="title-font font-medium text-gray-900"
                              v-if="vacancy.address && vacancy.neighborhood"
                            >
                              {{ vacancy.address }}, {{ vacancy.number }} -
                              {{ vacancy.neighborhood }}, {{ vacancy.city }} -
                              {{ vacancy.state }} -
                              {{ vacancy.cep }}
                            </span>
                            <span
                              class="title-font font-medium text-gray-900"
                              v-else
                            >
                              Não informado
                            </span>
                          </span></a
                        >
                        <br />

                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Salário:
                            <span
                              v-if="vacancy.salary_until != ''"
                              class="title-font font-medium text-gray-900"
                            >
                              Entre {{ vacancy.salary }} à
                              {{ vacancy.salary_until }}
                            </span>
                            <span
                              v-else
                              class="title-font font-medium text-gray-900"
                            >
                              {{ vacancy.salary }}
                            </span>
                          </span> </a
                        ><br />

                        <a class="inline-flex">
                          <span
                            class="title-font font-medium font-bold text-gray-900"
                            >Periodo de Inscrição:
                            <span class="title-font font-medium text-gray-900">
                              {{ vacancy.validity_from }} a
                              {{ vacancy.validity_to }}
                            </span>
                          </span> </a
                        ><br />

                        <br />

                        <div v-if="vacancy.is_subscrible == 0">
                          <router-link
                            class="flex flex-row justify-center items-center flex-shrink-0 h-10 w-60 border-2 border-2 rounded-lg border-green-1002 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                            :to="{
                              name: 'vacancy-description',
                              params: { id: vacancy.id }
                            }"
                          >
                            Ver descrição da vaga
                          </router-link>
                        </div>
                        <div v-else-if="vacancy.link_extern_option">
                          <router-link
                            class="flex flex-row justify-center items-center flex-shrink-0 h-10 w-60 border-2 border-2 rounded-lg border-yellow-100 bg-yellow-500 text-white rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                            :to="{
                              name: 'vacancy-description',
                              params: { id: vacancy.id }
                            }"
                          >
                            interessado - Vaga Externa
                          </router-link>
                        </div>
                        <div v-else>
                          <router-link
                            class="flex flex-row justify-center items-center flex-shrink-0 h-10 w-60 border-2 border-2 rounded-lg border-green-1002 bg-green-500 text-white rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                            :to="{
                              name: 'vacancy-description',
                              params: { id: vacancy.id }
                            }"
                          >
                            Inscrito
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../../layouts/DashboardLayout';
import FieldTooltip from '../../../../components/FieldTooltip';
import LoaderAnimation from '../../../../components/LoaderAnimation.vue';
import { mapGetters } from 'vuex';
import store from '@/store';
import axios from '@/utils/axios';

export default {
  name: 'search-vacancies',

  title() {
    return `${process.env.VUE_APP_NAME} | Buscar Oportunidades`;
  },

  components: {
    DashboardLayout,
    FieldTooltip,
    LoaderAnimation
  },

  data() {
    return {
      vacancySchedules: [],
      pagination1: {},
      cityIdUser: '',
      cityNameUser: '',
      telephoneParticipant: '',

      advancedSearch: {
        companies: {
          isLoading: false,
          options: [],
          draw: 0,
          selected: ''
        },
        cities: {
          options: [],
          filteredOptions: [],
          selected: []
        },
        name: ''
      },

      isLoading: false,
      search: '',
      showAdvancedSearch: false,
      keywords: []
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      isAvailable: 'isAvailable'
    }),

    /**
     * Conta quantos campos da pesquisa avançada foram preenchidos
     *
     * Regra:
     *  - Se o campo for objeto, verifica o valor do atributo selected, caso
     *  ele não seja array, verifico somente se é diferente de nulo, caso
     *  contrário valido se possui algum elemento dentro do array
     *  - Se o campo não for objeto, apenas verifico se ele não está vazio
     *
     * @returns {number}
     */
    countAdvancedSearchFieldsFilled() {
      return Object.values(this.advancedSearch).filter(
        field =>
          (typeof field === 'object' &&
            ((!Array.isArray(field.selected) && field.selected !== null) ||
              (Array.isArray(field.selected) && field.selected.length))) ||
          (typeof field !== 'object' && field)
      ).length;
    }
  },

  props: {
    participant: {
      required: true,
      type: Number
    }
  },

  methods: {
    async fetchParticipant() {
      const participant = await axios.get('/api/perfil');
      if (participant.data.data) {
        this.telephoneParticipant =
          participant.data.data.cellphone == ''
            ? participant.data.data.telephone
            : participant.data.data.cellphone;
      }
    },
    async fetchAddress() {
      const response = await axios.get(`/api/perfil/endereco`);
      this.cityIdUser = response.data.data.city.id;
      this.cityNameUser = response.data.data.city.name;
      this.advancedSearch.cities.selected.push({
        name: response.data.data.city.name,
        id: response.data.data.city.id
      });
      store.commit('setCities', this.advancedSearch.cities.selected);
      this.fetchVacancySchedules();
    },

    async fetchVacancySchedules() {
      this.isLoading = true;
      const address =
        this.advancedSearch.cities.selected != ''
          ? this.advancedSearch.cities.selected
          : '';

      const response = await axios.post('/api/vagas/listagem', {
        keywords: this.search,
        company: this.advancedSearch.companies.selected.name,
        address: address,
        participant: this.user.participant_id
      });

      this.vacancySchedules = response.data;
      this.pagination1 = response.data;

      if (this.user.role.name === 'Instructor') {
        await response.data.data.forEach((vacancy, index) => {
          axios
            .post('/api/vagas/inscricao-instructorview', {
              participant: this.user.id,
              vacancy: vacancy.id
            })
            .then(({ data }) => {
              this.vacancySchedules.data[index].is_subscrible = data;
            });
        });
      } else {
        await response.data.data.forEach((vacancy, index) => {
          axios
            .post('/api/vagas/inscricao-participante', {
              participant: this.user.participant_id,
              vacancy: vacancy.id
            })
            .then(({ data }) => {
              this.vacancySchedules.data[index].is_subscrible = data;
            });
        });
      }
      this.isLoading = false;
    },

    fetchCompanies(search) {
      this.advancedSearch.companies.isLoading = true;
      this.advancedSearch.companies.draw++;

      axios
        .post('api/vagas/buscar', {
          name: search,
          draw: this.advancedSearch.companies.draw
        })
        .then(({ data }) => {
          if (this.advancedSearch.companies.draw !== data.draw) return;

          this.advancedSearch.companies.options = [];

          data.data.forEach(company => {
            this.advancedSearch.companies.options.push({
              name: company.company_name,
              id: company.id
            });
          });

          this.advancedSearch.companies.draw = 0;
        })
        .finally(() => {
          this.advancedSearch.companies.isLoading = false;
        });
    },

    addCity(city) {
      const tag = {
        name: city,
        id: city
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    selectKeyword(keyword) {
      this.search = keyword;

      this.keywords = [];
    },

    searchKeyword() {
      if (this.search.length >= 3) {
        return axios
          .post('api/busca/palavras-chave-vagas', {
            search: this.search
          })
          .then(response => {
            this.keywords = [];

            response.data.data.forEach(keyword =>
              this.keywords.push(keyword.label)
            );
          });
      }
    },

    async fetchCities() {
      await axios.get('api/estados/11/cidades').then(response => {
        response.data.data.forEach(city => {
          this.advancedSearch.cities.options.push({
            name: city.name,
            id: city.id
          });
        });
      });
    },

    submit: function() {
      store.commit('setSearch', this.search);

      if (this.advancedSearch.companies.selected != null) {
        store.commit('setCourse', this.advancedSearch.companies.selected.id);
      } else {
        store.commit('setCourse', null);
      }

      let cities = this.advancedSearch.cities.selected;

      store.commit('setCities', cities);

      localStorage.setItem(
        'searchParams',
        JSON.stringify({
          'Palavra Buscada': this.search,
          Curso: this.advancedSearch.companies.selected
            ? this.advancedSearch.companies.selected.name
            : null,
          'Cidades Selecionadas': cities ?? null
        })
      );
      this.searchCompanies = true;
      this.fetchVacancySchedules();
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },

    citiesSearchChange(search) {
      this.advancedSearch.cities.filteredOptions = search
        ? this.advancedSearch.cities.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.advancedSearch.cities.options;
    }
  },
  created() {
    this.fetchParticipant();
    this.fetchAddress();
    this.fetchCities();
    //store.commit('clearFields');
  }
};
</script>

<style scoped></style>
